import * as React from 'react';
import { Accordion as MuiAccordion, AccordionDetails, Box, Typography, styled, AccordionProps } from '@mui/material';
import { ProjectListAccordionItemSummary } from '../../../../../../design/molecules/ProjectListAccordionItem';
import ResourceListItem, { DenseChip }  from '../../../../../../design/atoms/ResourceListItem';
import DenseIconButton from '../../../../../../design/atoms/buttons/DenseIconButton';
import { EditLoadIcon, MapIcon } from '../../../../../../design/atoms/icons';
import { MoreHoriz } from '@mui/icons-material';
import { Task, TiffResource } from '../../../../../../dummy-server/dtos';
import { getTemplate, getTiffResource, getTile } from '../../../../../../dummy-server';
import { useNavigate } from 'react-router-dom';

import useOrthophotoMapStore from '../../../../../../pages/OrthophotoMapPage/orthophoto-map.store';
import useSwipeMapStore from '../../../../../../pages/SwipePage/swipe-map.store';

function BaseTiffResourceItem({resource, title}: {resource: TiffResource, title: string}){
  const navigate = useNavigate();
  const {setOrthoMapUrl, setZoom, setCenter } = useOrthophotoMapStore();

  return <Box sx={{
    paddingLeft: '16px',
    paddingTop: '8px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '4px'
  }}>
    <Typography sx={{
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '12px',
      lineHeight: '16px',
      letterSpacing: '0.5px',
      color: '#000000',
      userSelect: 'none'
    }}>
      {title}
    </Typography>
    <ResourceListItem>
      <DenseChip type={resource.type}/>
      <div style={{
        userSelect: "none",
        textAlign: 'left'
      }}>
        {resource.name}
      </div>
      <div style={{flexGrow: 1}}/>
      <DenseIconButton onClick={()=>{
        if(resource.tile_ids.length>0){
          const tile = getTile(resource.tile_ids[0]);
          if(tile){
            setOrthoMapUrl(`${tile.base_url}/{z}/{x}/{-y}.png`);
            setZoom(13);
            setCenter(tile.center!);
            if (window.location.pathname !== '/orthophoto-map') {
              navigate('/orthophoto-map');
            }
          }
        }
      }}>
        <MapIcon />
      </DenseIconButton>
    </ResourceListItem>
  </Box>
}

function ChangeDetectionItem({task}: {task: Task}) {
  const {setTask} = useSwipeMapStore();
  const navigate = useNavigate();
  return <Box sx={{
    paddingLeft: '16px',
    paddingTop: '8px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '4px'
  }}>
    <Typography sx={{
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '12px',
      lineHeight: '16px',
      letterSpacing: '0.5px',
      color: '#000000',
      userSelect: 'none'
    }}>
      탐지결과
    </Typography>
    <ResourceListItem>
      <DenseChip type={getTemplate(task.template_id).name}/>
      <div style={{
        userSelect: "none",
        textAlign: 'left'
      }}>
        {task.name}
      </div>
      <div style={{flexGrow: 1}}/>
      <DenseIconButton onClick={()=>{
        setTask(task);
        navigate('/swipe');
      }}>
        <EditLoadIcon />
      </DenseIconButton>
    </ResourceListItem>
  </Box>
}

export default function TaskAccordion({task}: {task: Task}) {

  const [eeee, setEeee] = React.useState(false);

  const handleClick = (event: React.UIEvent) => {
    console.log('click');
    event.stopPropagation();
    event.preventDefault();
  }

  const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(() => ({
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&::before': {
      display: 'none',
    },
    backgroundColor:'transparent'
  }));

  return <Accordion sx={{width: '100%'}} expanded={eeee} onChange={()=>setEeee(!eeee)}>
  <ProjectListAccordionItemSummary>
    <Box
      sx={{
        marginLeft: '8px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '8px',
        flexGrow: 1,
      }}
    >
      <Box 
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '4px',
        }}
      >
        <Typography>
          {task.name}
        </Typography>
        <DenseChip type={getTemplate(task.template_id).name}/>
      </Box>
      <div style={{flexGrow: 1}}/>
      {/* <DenseIconButton onClick={handleClick}>
        <MapIcon />
      </DenseIconButton> */}
      <DenseIconButton onClick={handleClick}>
        <MoreHoriz />
      </DenseIconButton>
    </Box>
  </ProjectListAccordionItemSummary>
  <AccordionDetails>
    <BaseTiffResourceItem title="기준영상" resource={getTiffResource(task.input_ids[0])}/>
    <BaseTiffResourceItem title="대상영상" resource={getTiffResource(task.input_ids[1])}/>
    <ChangeDetectionItem task={task}/>
  </AccordionDetails>
</Accordion>
}