import { styled, Box, BoxProps } from '@mui/material';
import { primary } from '../tokens/colors';

export const ResourceListItem = styled((props: BoxProps)=>(
  <Box {...props} />
))(()=>({
// ))(({theme})=>({
  display: 'flex',
  padding: '8px 16px',
  alignItems: 'center',
  gap: '8px',
  alignSelf: 'stretch',
  backgroundColor: 'white'
}));

export const DenseChip = styled((props:{type:string})=>(
  <div style={{
    padding: 'var(--size-xs, 4px) var(--size-sm, 8px)',
    gap: '10px',
    color: 'white',
    backgroundColor: primary,
    borderRadius: '8px',
    userSelect: 'none',
    width: 'fit-content',
    alignItems: 'center',
    justifyContent: 'center',
  }}>
    {props.type}
  </div>
))(()=>({
}));


export default ResourceListItem;