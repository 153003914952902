import * as React from 'react';

import { Box, BoxProps, Button, ButtonProps, styled } from '@mui/material';
import { tab_unselected_bg_color, tab_unselected_color, tab_unselected_hover_bg_color } from '../../tokens/colors';

export const TabTabButton = (props: ButtonProps & {name: string, value: string, setValue: (value: string) => void}) => {

  const [sx, setSx] = React.useState({});


  React.useEffect(() => {
    if(props.name !== props.value){
      setSx({
        color: tab_unselected_color,
        backgroundColor: tab_unselected_bg_color,
        '&:hover': {
          backgroundColor: tab_unselected_hover_bg_color,
        },
      });
    }else {
      setSx({});
    }
    
  }, [props.value, props.name]);

  return (
    <Button
      color='primary'
      variant='contained'
      disableElevation
      sx={{
        borderRadius: 16,
        ...sx,
        ...props.sx,
      }}
      onClick={() => props.setValue(props.name)}
      // {...originProps}
    >
      {props.children}
    </Button>
  );
};

export const Tabs = styled((props: BoxProps)=>(
  <Box {...props}/>
))(()=>({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '0px 16px',
  gap: '8px',
  height: '52px',
  background: '#FFFFFF',
  flex: 'none',
  alignSelf: 'stretch',
  flexGrow: 0,
  alignContent: 'center',
  zIndex: 1,
}));


