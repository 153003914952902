import { useNavigate } from "react-router-dom";
import DenseIconButton from "../../../../../../design/atoms/buttons/DenseIconButton";
import ResourceListItem, { DenseChip } from "../../../../../../design/atoms/ResourceListItem";
import { getTile } from "../../../../../../dummy-server";
import { TiffResource } from "../../../../../../dummy-server/dtos";
import useOrthophotoMapStore from "../../../../../../pages/OrthophotoMapPage/orthophoto-map.store";
import { MapIcon } from "../../../../../../design/atoms/icons";

export default function TiffResourceList({ resources }: { resources: TiffResource[] }){
  
  const navigate = useNavigate();
  const {setOrthoMapUrl, setZoom, setCenter } = useOrthophotoMapStore();
  
  const handleClick = (resource: TiffResource) => {
    if(resource.tile_ids.length>0){
      const tile = getTile(resource.tile_ids[0]);
      if(tile){
        setOrthoMapUrl(`${tile.base_url}/{z}/{x}/{-y}.png`);
        setZoom(13);
        setCenter(tile.center!);
        if (window.location.pathname !== '/orthophoto-map') {
          navigate('/orthophoto-map');
        }
      }
    }
  }
  return (
      <>
      {resources.map((resource)=>(
            <ResourceListItem key={resource.id}>
              <div style={{userSelect: "none"}}>{resource.name}</div>
              <DenseChip type={resource.type}/>
              <div style={{flexGrow: 1}}/>
              <DenseIconButton
                onClick={()=>handleClick(resource)}
                color='primary'>
                <MapIcon />
              </DenseIconButton>
            </ResourceListItem>
      ))}
      </>
  );
}