import { SvgIcon,SvgIconProps } from '@mui/material';

export function UploadIcon(props:SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4 22V20H20V22H4ZM9 18V11H5L12 2L19 11H15V18H9Z" fill="currentColor"/>
      </svg>
    </SvgIcon>
  );
}

export function AddTaskIcon(props:SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.5 18.5H15.5V15.5H18.5V13.5H15.5V10.5H13.5V13.5H10.5V15.5H13.5V18.5ZM7.00001 20V8.97501C7.00001 8.42501 7.20001 7.95835 7.60001 7.57501C8.00001 7.19168 8.47501 7.00001 9.02501 7.00001H20C20.55 7.00001 21.0208 7.19585 21.4125 7.58751C21.8042 7.97918 22 8.45001 22 9.00001V17L17 22H9.00001C8.45001 22 7.97918 21.8042 7.58751 21.4125C7.19585 21.0208 7.00001 20.55 7.00001 20ZM2.02501 6.25001C1.92501 5.70001 2.03335 5.20418 2.35001 4.76251C2.66668 4.32085 3.10001 4.05001 3.65001 3.95001L14.5 2.02501C15.05 1.92501 15.5458 2.03335 15.9875 2.35001C16.4292 2.66668 16.7 3.10001 16.8 3.65001L17.05 5.00001H9.00001C7.90001 5.00001 6.95835 5.39168 6.17501 6.17501C5.39168 6.95835 5.00001 7.90001 5.00001 9.00001V18.55C4.73335 18.4 4.50418 18.2 4.31251 17.95C4.12085 17.7 4.00001 17.4167 3.95001 17.1L2.02501 6.25001Z" fill="#24326B"/>
      </svg>
    </SvgIcon>
  );
}

export function OpenProjectIcon(props:SvgIconProps) {
  return <SvgIcon {...props}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4 20C3.45 20 2.97917 19.8042 2.5875 19.4125C2.19583 19.0208 2 18.55 2 18V6C2 5.45 2.19583 4.97917 2.5875 4.5875C2.97917 4.19583 3.45 4 4 4H10L12 6H20C20.55 6 21.0208 6.19583 21.4125 6.5875C21.8042 6.97917 22 7.45 22 8H4V18L6.4 10H23.5L20.925 18.575C20.7917 19.0083 20.5458 19.3542 20.1875 19.6125C19.8292 19.8708 19.4333 20 19 20H4Z" fill="#24326B"/>
    </svg>
  </SvgIcon>
}

export function AddProjectIcon(props:SvgIconProps) {
  return <SvgIcon {...props}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14 16H16V14H18V12H16V10H14V12H12V14H14V16ZM4 20C3.45 20 2.97917 19.8042 2.5875 19.4125C2.19583 19.0208 2 18.55 2 18V6C2 5.45 2.19583 4.97917 2.5875 4.5875C2.97917 4.19583 3.45 4 4 4H10L12 6H20C20.55 6 21.0208 6.19583 21.4125 6.5875C21.8042 6.97917 22 7.45 22 8V18C22 18.55 21.8042 19.0208 21.4125 19.4125C21.0208 19.8042 20.55 20 20 20H4Z" fill="#24326B"/>
    </svg>
  </SvgIcon>
}

export function MapIcon(props:SvgIconProps) {
  return <SvgIcon {...props}>
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15 21.5L9 19.4L4.35 21.2C4.01667 21.3333 3.70833 21.2958 3.425 21.0875C3.14167 20.8792 3 20.6 3 20.25V6.25C3 6.03333 3.0625 5.84167 3.1875 5.675C3.3125 5.50833 3.48333 5.38333 3.7 5.3L9 3.5L15 5.6L19.65 3.8C19.9833 3.66667 20.2917 3.70417 20.575 3.9125C20.8583 4.12083 21 4.4 21 4.75V18.75C21 18.9667 20.9375 19.1583 20.8125 19.325C20.6875 19.4917 20.5167 19.6167 20.3 19.7L15 21.5ZM14 19.05V7.35L10 5.95V17.65L14 19.05ZM16 19.05L19 18.05V6.2L16 7.35V19.05ZM5 18.8L8 17.65V5.95L5 6.95V18.8Z" fill="currentColor"/>
    </svg>
  </SvgIcon>
}

export function EditLoadIcon(props:SvgIconProps){
  return <SvgIcon {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="currentColor"><path d="M640-475v-325h80v245l-80 80ZM160-160v-640h80v640h-80Zm240-480v-160h80v160h-80Zm0 240v-160h80v160h-80Zm0 240v-160h80v160h-80Zm160 0v-123l221-220q9-9 20-13t22-4q12 0 23 4.5t20 13.5l37 37q8 9 12.5 20t4.5 22q0 11-4 22.5T903-380L683-160H560Zm300-263-37-37 37 37ZM620-220h38l121-122-18-19-19-18-122 121v38Zm141-141-19-18 37 37-18-19Z"/></svg>
  </SvgIcon>
}