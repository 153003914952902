import { Outlet } from "react-router-dom";

export default function EmptyLayout() {
  return (
    <div style={{ height: '100vh' }}>
      {/* An <Outlet> renders whatever child route is currently active,
          so you can think about this <Outlet> as a placeholder for
          the child routes we defined above. */}
      <Outlet />
    </div>
  );
}
