import { Routes, Route } from "react-router-dom";
import EmptyLayout from "./layouts/EmptyLayout";
import MainLayout from "./layouts/MainLayout";
import SwipePage from "./pages/SwipePage";
import OrthophotoMapPage from "./pages/OrthophotoMapPage";

// 스타일
import "ol/ol.css"
import "ol-ext/dist/ol-ext.css"

function NotFoundPage() {
  return (
    <div style={{ 
      height: "100dvh",
      width: "100dvw",
      display: "flex",
      justifyContent: "center",
      alignItems: "center" 
    }}>
      <h1>404 Not Found</h1>
    </div>
  );
}

export default function App() {
  return (
    <div style={{ height: "100dvh" }}>
      <Routes>
        <Route path="/" element={<MainLayout />}>
          <Route index element={<OrthophotoMapPage />} />
          <Route path="orthophoto-map" element={<OrthophotoMapPage />} />
          <Route path="swipe" element={<SwipePage />} />
        </Route>
        {/* Notfound 페이지 */}
        <Route path="/" element={<EmptyLayout />}>
          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </Routes>
    </div>
  );
}