import { create } from 'zustand'
import { persist } from 'zustand/middleware';

interface OrthophotoMapState {
  base_map_url: string
  ortho_map_url: string
  center: [number, number]
  zoom: number
  setBaseMapUrl: (url: string) => void
  setOrthoMapUrl: (url: string) => void
  setCenter: (center: [number, number]) => void
  setZoom: (zoom: number) => void
}

const useOrthophotoMapStore = create<OrthophotoMapState>()(
  persist(
    (set) => ({
      base_map_url: 'https://tile.openstreetmap.org/{z}/{x}/{y}.png',
      ortho_map_url: '/tiles/nowon-2022/{z}/{x}/{-y}.png',
      center: [139.7392358, 35.652832],
      zoom: 14,
      setBaseMapUrl: (url: string) => set({ base_map_url: url }),
      setOrthoMapUrl: (url: string) => set({ ortho_map_url: url }),
      setCenter: (center: [number, number]) => set({ center }),
      setZoom: (zoom: number) => set({ zoom }),
    }),
    {
      name: 'orthophoto-map-storage', // unique name
    }
  )
);

export default useOrthophotoMapStore;