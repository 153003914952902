import { create } from 'zustand'
import { persist } from 'zustand/middleware';

interface SelectedProject {
  id: number
  setId: (id: number) => void
}

const useSelectedProjectStore = create<SelectedProject>()(
  persist(
    (set) => ({
      id: 0,
      setId: (id: number) => set({ id }),
    }),
    {
      name: 'selected-project-storage', // unique name
    }
  )
);

export default useSelectedProjectStore;