import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { DialogTitle } from '@mui/material';
import { TextField } from '@mui/material';

export default function CreateProjectDialog({ open, setOpen }: { open: boolean, setOpen: (open: boolean) => void }) {

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={()=>{setOpen(false)}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          프로젝트 생성
        </DialogTitle>
        <DialogContent>
          <TextField variant="filled" label='프로젝트 이름'/>
        </DialogContent>
        <DialogActions style={{backgroundColor: 'transparent'}}>
          <Button onClick={()=>{setOpen(false)}}>취소</Button>
          <Button onClick={()=>{setOpen(false)}} variant='contained'>
            생성
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
