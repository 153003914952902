import { styled } from '@mui/material/styles';
import ArrowRightSharpIcon from '@mui/icons-material/ArrowRightSharp';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import { panel_header_height, panel_padding_x } from '../tokens/spacing';

export const ProjectListAccordionItemSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowRightSharpIcon color='primary'/>}
    {...props}
  />
// ))(({ theme }) => ({
))(() => ({
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    margin: 0,
  },
  padding: '8px 16px',
  backgroundColor: 'white',
}));
