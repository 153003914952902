import { Drawer as MuiDrawer, DrawerProps, styled, ToolbarProps, Toolbar } from '@mui/material';

const Drawer = styled((props: DrawerProps)=>(
  <MuiDrawer  
    variant="persistent"
    anchor="left"
    sx={{
      // width: props.width,
      
      '& .MuiDrawer-paper': {
        // width: props.width,
        boxSizing: 'border-box',
      },
    }}
    {...props} 
  />
// ))(({theme})=>({
))(()=>({
  flexShrink: 0,
}));

export const DrawerHeader = styled((props: ToolbarProps)=>(
  <Toolbar {...props} />
))(({theme})=>({
  display: 'flex',
  alignItems: 'center',
  paddingRight:'0!important',
  justifyContent: 'flex-end',
  // backgroundColor: theme.palette.primary.main,
  // color: theme.palette.primary.contrastText,
}));


export default Drawer;