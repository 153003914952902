import * as React from 'react';
import { styled } from '@mui/material/styles';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import AccordionSummary from '../../../../../design/molecules/accordion/AccordionSummary';
import { Box, TextField} from '@mui/material';
// import MoreVertIcon from '@mui/icons-material/MoreVert';
import DenseIconButton from '../../../../../design/atoms/buttons/DenseIconButton';
import { AddProjectIcon, AddTaskIcon, OpenProjectIcon, UploadIcon } from '../../../../../design/atoms/icons';
import { panel_background_color } from '../../../../../design/tokens/colors';
import { Tabs, TabTabButton } from '../../../../../design/atoms/buttons/TabTabButton';
import ResourceListItem from '../../../../../design/atoms/ResourceListItem';
import { geo_resources, tiff_resources, getTask, getProject } from '../../../../../dummy-server';
import { GeoResource, Task, TiffResource } from '../../../../../dummy-server/dtos';
import TiffResourceList from './resource/TiffResourceList';
import GeoResourceList from './resource/GeoResourceList';
import TaskAccordion from './TaskAccordion';
import UploadResourceDialog from './resource/UploadResourceDialog';
import CreateProjectDialog from './CreateProjectDialog';
import ProjectListDialog from './ProjectListDialog';
import useSelectedProjectStore from './selected-project.store';
import CreateTaskDialog from './TaskAccordion/CreateTaskDialog';

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
  backgroundColor:'transparent'
}));

const SHOW_SCROLLBAR = /Mobi|Android/i.test(navigator.userAgent);
const AccordionDetails = styled(MuiAccordionDetails)
// (({ theme }) => ({
(() => ({
  padding: 0,
  overflowY: "scroll",
  scrollbarWidth: SHOW_SCROLLBAR ? 'auto' : 'none', // Firefox,
  '&::-webkit-scrollbar': {
    display: SHOW_SCROLLBAR ? 'auto' : 'none', // Chrome, Safari, Opera 수정
  },
}));

function ResourceTabs({tab, setTab}: {tab: string, setTab: (tab: string) => void}){
  return <Tabs>
    <TabTabButton name='정사영상' value={tab} setValue={setTab} >
      <Typography>
        정사영상
      </Typography>
    </TabTabButton>
    <TabTabButton name='공간 데이터'value={tab} setValue={setTab}>
      <Typography>
        공간 데이터
      </Typography>
    </TabTabButton>
  </Tabs>
}

export default function DrawerContent({ height, width }: { height: number,width: number }) {

  const [p1, setP1] = React.useState(false);
  const [p2, setP2] = React.useState(true);

  const [queriedTiffResources, setQueriedTiffResources] = React.useState<TiffResource[]>([]);
  const [queriedGeoResources, setQueriedGeoResources] = React.useState<GeoResource[]>([]);
  const [queriedTasks, setQueriedTasks] = React.useState<Task[]>([]);
  const {id:selectedProjectId} = useSelectedProjectStore();

  const [query, setQuery] = React.useState('');
  const [taskQuery, setTaskQuery] = React.useState('');

  React.useEffect(()=>{
    setQueriedTiffResources(tiff_resources.filter((resource)=>resource.name.includes(query)));
    setQueriedGeoResources(geo_resources.filter((resource)=>resource.name.includes(query)));
  }, [query]);

  React.useEffect(()=>{
    setQueriedTasks(getProject(selectedProjectId).task_ids.map((task_id) => getTask(task_id)).filter((task)=>task.name.includes(taskQuery)));
  }, [taskQuery, selectedProjectId]);

  const handleClick = (event?: React.UIEvent)=>{
    console.log(event);
    event?.stopPropagation();
    event?.preventDefault();
    console.log("click1");
  }

  const [tab, setTab] = React.useState('정사영상');
  const drawer_height = height;
  
  // 프로젝트 생성 모달
  const [openProjectDialog, setOpenProjectDialog] = React.useState(false);
  // 리소스 업로드 모달
  const [openResourceDialog, setOpenResourceDialog] = React.useState(false);
  // 프로젝트 리스트 모달
  const [openProjectListDialog, setOpenProjectListDialog] = React.useState(false);
  // 작업 생성 모달
  const [openTaskDialog, setOpenTaskDialog] = React.useState(false);

  return (
    <div style={{width: width,height:drawer_height, backgroundColor: panel_background_color}} >
      <Accordion 
        expanded={p1}
        onChange={()=>setP1(!p1)}
        sx={{backgroundColor:'transparent', border: '0px'}}
      >
        <AccordionSummary>
          <Box display="flex" flexGrow={1} justifyContent="flex-start" alignItems="center" gap={"4px"}>
            <Typography alignSelf="center">리소스</Typography>
            <div style={{flexGrow: 1}}/>
            <DenseIconButton onClick={(e)=>{e.stopPropagation();setOpenResourceDialog(true)}}>
              <UploadIcon />
            </DenseIconButton>
            {/* <DenseIconButton onClick={handleClick}>
              <MoreVertIcon />
            </DenseIconButton> */}
          </Box>
        </AccordionSummary>
        <AccordionDetails  sx={{height: (drawer_height - 8*4*2)/((p1?1:0)+(p2?1:0)),}}>
          <Box sx={{
            boxSizing: 'border-box',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            padding: 0,
            gap: '4px',
            // borderBottom: '4px solid #24326B',
            flex: 'none',
            order: 1,
            flexGrow: 1,
          }}>
            <Box sx={{position: 'sticky', top: 0, left: 0, width: '100%',zIndex: 1}}>
              <ResourceTabs tab={tab} setTab={setTab}/>
              <ResourceListItem>
                <TextField
                  size='small'
                  fullWidth
                  id="outlined-controlled"
                  label="리소스 검색"
                  value={query}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setQuery(event.target.value);
                  }}
                />
              </ResourceListItem>
            </Box>
            {tab==='정사영상'&&<TiffResourceList resources={queriedTiffResources}/>}
            {tab==='공간 데이터'&&<GeoResourceList resources={queriedGeoResources}/>}
          </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={p2} onChange={()=>setP2(!p2)}>
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <Box display="flex" flexGrow={1} justifyContent="flex-start" alignItems="center" gap={1}>
            <Typography>{getProject(selectedProjectId).name}</Typography>
            <DenseIconButton onClick={(e)=>{e.stopPropagation();setOpenProjectListDialog(true)}}>
              <OpenProjectIcon />  
            </DenseIconButton>
            <DenseIconButton onClick={(e)=>{e.stopPropagation();setOpenProjectDialog(true)}}>
              <AddProjectIcon />
            </DenseIconButton>
            <div style={{flexGrow: 1}}/>
            <DenseIconButton onClick={(e)=>{e.stopPropagation();setOpenTaskDialog(true)}}>
              <AddTaskIcon />
            </DenseIconButton>
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={{
          height: (drawer_height - 8*4*2)/((p1?1:0)+(p2?1:0)),
          gap: '4px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}>
          <ResourceListItem>
            <TextField
              size='small'
              fullWidth
              id="outlined-controlled"
              label="작업 검색"
              value={taskQuery}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setTaskQuery(event.target.value);
              }}
            />
          </ResourceListItem>
          {/* {queriedProjects.map((project) => (
           <TaskAccordion key={projpects[0].id} task={getTask(project.task_ids[0])} />
          ))} */}
          {queriedTasks.map((task) => (
           <TaskAccordion key={task.id} task={task} />
          ))}
          {/* <TaskAccordion task={getTask(projects[0].task_ids[0])} /> */}
        </AccordionDetails>
      </Accordion>
      <UploadResourceDialog open={openResourceDialog} setOpen={setOpenResourceDialog}/>
      <CreateProjectDialog open={openProjectDialog} setOpen={setOpenProjectDialog}/>
      <ProjectListDialog open={openProjectListDialog} setOpen={setOpenProjectListDialog}/>
      <CreateTaskDialog open={openTaskDialog} setOpen={setOpenTaskDialog}/>
    </div>
  );
  
}