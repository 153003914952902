import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { projects } from '../../../../../dummy-server';
import { ButtonBase } from '@mui/material';
import useSelectedProjectStore from './selected-project.store';

export default function ProjectListDialog({ open, setOpen }: { open: boolean, setOpen: (open: boolean) => void }) {

  const {setId:setSelectedProjectId} = useSelectedProjectStore();

  const SHOW_SCROLLBAR = /Mobi|Android/i.test(navigator.userAgent);
  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={()=>{setOpen(false)}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          프로젝트 리스트
        </DialogTitle>
        <DialogContent sx={{
          maxHeight: 400,
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          scrollbarWidth: SHOW_SCROLLBAR ? 'auto' : 'none', // Firefox,
          '&::-webkit-scrollbar': {
            display: SHOW_SCROLLBAR ? 'auto' : 'none', // Chrome, Safari, Opera 수정
          },
        }}>

          {projects.map((project)=>{
            return <ButtonBase 
            key={project.id} 
            onClick={()=>{setSelectedProjectId(project.id);setOpen(false)}}
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              padding: 16,
              minWidth: 290,
              height: 56,
            
              background: '#ECECEC',
              borderRadius: 8,
              flex: 'none',
              order: 1,
              alignSelf: 'stretch',
              flexGrow: 0,
            }}>
              {project.name}
            </ButtonBase>
          })}
          
        </DialogContent>
        <DialogActions style={{backgroundColor: 'transparent'}}>
          <Button onClick={()=>{setOpen(false)}}>닫기</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
