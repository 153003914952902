import { styled } from '@mui/material/styles';
import ArrowRightSharpIcon from '@mui/icons-material/ArrowRightSharp';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import { panel_header_height, panel_padding_x } from '../../tokens/spacing';

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowRightSharpIcon color='primary'/>}
    {...props}
  />
// ))(({ theme }) => ({
))(() => ({
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    margin: 0,
  },
  '&.Mui-expanded': {
    minHeight: panel_header_height,
    maxHeight: panel_header_height,
  },
  minHeight: panel_header_height,
  maxHeight: panel_header_height,
  padding: 0,
  paddingLeft: panel_padding_x,
  paddingRight: panel_padding_x,
}));

export default AccordionSummary;