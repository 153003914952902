import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { DialogTitle } from '@mui/material';

export default function UploadResourceDialog({ open, setOpen }: { open: boolean, setOpen: (open: boolean) => void }) {

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={()=>{setOpen(false)}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          리소스 업로드
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            현재 사용 가능한 파일 형식은 위치 정보가 존재하는 tiff 파일 뿐입니다.
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{backgroundColor: 'transparent'}}>
          <Button onClick={()=>{setOpen(false)}}>취소</Button>
          <Button onClick={()=>{setOpen(false)}} variant='contained'>
            업로드
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
