// theme.ts
import { createTheme } from "@mui/material/styles";
import { primary } from "./design/tokens/colors"

// eslint-disable-next-line import/prefer-default-export
export const theme = createTheme({
  palette: {
    primary: {
      main: primary,
      // light: main값을 통해 계산됨
      // dark: main값을 통해 계산됨
      // contrastText: main값을 통해 계산됨
    }
  },
});