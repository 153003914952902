import { create } from 'zustand'
import { persist } from 'zustand/middleware';

interface DrawerState {
  open: boolean
  setOpen: (open: boolean) => void
}

const useDrawerStore = create<DrawerState>()(
  persist(
    (set) => ({
      open: false,
      setOpen: (open: boolean) => set({ open }),
    }),
    {
      name: 'drawer-storage', // unique name
    }
  )
);

export default useDrawerStore;