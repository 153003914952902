/* eslint-disable camelcase */
import React, { useEffect, useRef } from "react";
import { Map, View } from "ol";
import TileLayer from "ol/layer/Tile";
import { XYZ } from "ol/source";
import useOrthophotoMapStore from "./orthophoto-map.store";

export default function OrthophotoMapPage(){
  const map_ref = useRef<HTMLDivElement>(null)
  const map = useRef<Map>(new Map())

  const { base_map_url, ortho_map_url, center, zoom} = useOrthophotoMapStore();

  const base_tile_layer = useRef<TileLayer<XYZ>>(new TileLayer({
    source: new XYZ({
      url: base_map_url
    }),
  }));
  
  const ortho_tile_layer = useRef<TileLayer<XYZ>>(new TileLayer({
    source: new XYZ({
      url: ortho_map_url
    }),
  }));

  useEffect(() => {
    base_tile_layer.current.set('source', new XYZ({
      url: base_map_url
    }));
  }, [base_map_url]);

  useEffect(() => {
    ortho_tile_layer.current.set('source', new XYZ({
      url: ortho_map_url
    }));
  }, [ortho_map_url]);

  useEffect(() => {
    map.current.getView().setCenter(center);
    map.current.getView().setZoom(zoom);
  }, [center, zoom]);
  

  useEffect(() => {
    
    const tile_layer = new TileLayer({
      source: new XYZ({
        url: base_map_url
      }),
    });

    const view = new View({
      // 서울시청 경도와 위도
      center: center, 
      zoom: zoom
    })

    map.current.setLayers([tile_layer]);
    map.current.setView(view);
    map.current.setTarget(map_ref.current || '')
    
    if(ortho_map_url){
      map.current.addLayer(ortho_tile_layer.current);
    }
    return () => {
      if(map.current){
        // eslint-disable-next-line react-hooks/exhaustive-deps
        map.current.setTarget('');
      }
    };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div style={{ 
    display: "flex",
    height: "calc(100dvh - 48px)",
    width: "100wh",
    position: "relative"
  }}>
    <div ref={map_ref} style={{width: '100%', height: '100%'}}/>
  </div>
}