import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { Box, DialogTitle, MenuItem, Select } from '@mui/material';
import { TextField } from '@mui/material';
import { templates } from '../../../../../../dummy-server/index';

export default function CreateTaskDialog({ open, setOpen }: { open: boolean, setOpen: (open: boolean) => void }) {

  const [taskType, setTaskType] = React.useState(1);
  const [taskName, setTaskName] = React.useState('');
  const [taskDescription, setTaskDescription] = React.useState('');

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={()=>{setOpen(false)}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          작업 생성
        </DialogTitle>
        <DialogContent>
          <Box display="flex" flexDirection="column" gap={2}>
            <TextField size='small' variant="filled" label='작업 이름' value={taskName} onChange={(e)=>{setTaskName(e.target.value)}}/>
            <TextField size='small' variant="filled" label='작업 설명' value={taskDescription} onChange={(e)=>{setTaskDescription(e.target.value)}}/>
            <Select
              size='small'
              label="작업 종류"
              value={taskType}
              onChange={(e)=>{setTaskType(e.target.value as number)}}
            >
              {templates.map((template) => (
                <MenuItem key={template.id} value={template.id}>{template.name}</MenuItem>
              ))}
            </Select>
          </Box>
        </DialogContent>
        <DialogActions style={{backgroundColor: 'transparent'}}>
          <Button onClick={()=>{setOpen(false)}}>취소</Button>
          <Button onClick={()=>{setOpen(false)}} variant='contained'>
            생성
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
