/* eslint-disable camelcase */
import React, { useCallback, useEffect, useRef } from "react";
import { View } from "ol";
import Synchronize from "ol-ext/interaction/Synchronize";
// tools
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
// import useLatestPositionStore from "../../stores/latest-position.store";
// import useSwipeMapStore from "./swipe-map.store";
import useSwipeMapStore2, { MODE } from "./swipe-map.store";

export default function OLSyncMap(){
  
  // const lastPositionStore = useLatestPositionStore();

  const map_ref1 = useRef<HTMLDivElement>(null)
  const map_ref2 = useRef<HTMLDivElement>(null)
  
  const { map1, map2, mode, setMode, center, zoom } = useSwipeMapStore2();

  const genView = useCallback(()=>{
    // const center = lastPositionStore.position;
    // const zoom = lastPositionStore.zoom;
    
    const view = new View({
        center: center,
        zoom: zoom,
        maxZoom: 18,
        minZoom: 1,
    })
    
    return view;
  },[center, zoom]);

  useEffect(()=>{
    map1.setView( genView());
  },[map1, genView])
  
  useEffect(()=>{
    map2.setView( genView());
  },[map2, genView])

  useEffect(() => {
    // set current
    map1.setTarget(map_ref1.current as HTMLDivElement);
    map2.setTarget(map_ref2.current as HTMLDivElement);
    // Synchronize the maps 
    map1.addInteraction( new Synchronize({ maps: [map2] }) );
    map2.addInteraction( new Synchronize({ maps: [map1] }) );
    return () => {
      map1.setTarget('');
      map2.setTarget('');
    };
  }, [map1, map2]);

  return <div style={{ 
    display: "flex",
    height: "calc(100dvh - 48px)",
    width: "100wh",
    position: "relative"
  }}>
    <div ref={map_ref1} style={{height: "100%", width: mode === "compare" ? "50%" : "0%" }}/>
    <div ref={map_ref2} style={{height: "100%", width: mode === "compare" ? "50%" : "100%" }}/>
    <ToggleButtonGroup
      exclusive
      value={mode}
      onChange={(e : React.MouseEvent<HTMLElement>,v : string)=>{
        if(v===null) return;
        // console.log(v);
        setMode(v as MODE);
      }}
      style={{
        position: "absolute",
        top: "0", right: "0",
        margin: "10px",
        background:'white'
      }}
    >
      <ToggleButton value="compare" >
        <i className="fg-screen-dub" ></i>
      </ToggleButton>
      <ToggleButton value="swipev" >
        <i className="fg-screen-split-h" ></i>
      </ToggleButton>
      <ToggleButton value="swipeh" >
        <i className="fg-screen-split-v" ></i>
      </ToggleButton>
    </ToggleButtonGroup>
  </div>
}