import { GeoResource, Project, Task, TaskTemplate, TiffResource, Tile } from "./dtos";


export const tiles: Tile[] = [
  {"id": 1, "name": "송파 2020년도 타일", "base_url":"tiles/2020/Songpa",min_zoom: 1, max_zoom:18, center:[14150004.31318136, 4510511.087837362]},
  {"id": 2, "name": "성북 2020년도 타일", "base_url":"tiles/2020/Seongbuk",min_zoom: 1, max_zoom:18, center:[14139439.820897,4521583.0877791]},
  {"id": 3, "name": "성동 2020년도 타일", "base_url":"tiles/2020/Seongdong",min_zoom: 1, max_zoom:18, center:[14141674.2257162,4517939.0183909]},
  {"id": 4, "name": "서대문 2020년도 타일", "base_url":"tiles/2020/Seodaemun",min_zoom: 1, max_zoom:18, center:[14130539.9389276,4520153.7656922]},
  {"id": 5, "name": "서초 2020년도 타일", "base_url":"tiles/2020/Seocho",min_zoom: 1, max_zoom:18, center:[14141204.1235066,4506726.1284877]},
  {"id": 6, "name": "노원 2020년도 타일", "base_url":"tiles/2020/Nowon",min_zoom: 1, max_zoom:18, center:[14143861.8763493,4530712.6024805]},
  {"id": 7, "name": "마포 2020년도 타일", "base_url":"tiles/2020/Mapo",min_zoom: 1, max_zoom:18, center: [14126609.3590272,4518341.9329551]},
  {"id": 8, "name": "중랑 2020년도 타일", "base_url":"tiles/2020/Jungrang",min_zoom: 1, max_zoom:18, center:[14147881.7344813,4523960.9011925]},
  {"id": 9, "name": "중구 2020년도 타일", "base_url":"tiles/2020/Junggu",min_zoom: 1, max_zoom:18, center:[14137308.3866068,4517993.3667139]},
  {"id": 10, "name": "종로 2020년도 타일", "base_url":"tiles/2020/Jongro",min_zoom: 1, max_zoom:18, center:[14135219.2537231,4519352.587922]},
  {"id": 11, "name": "광진 2020년도 타일", "base_url":"tiles/2020/Gwangjin",min_zoom: 1, max_zoom:18,center:[14146745.2737998,4514451.3451788]},
  {"id": 12, "name": "관악 2020년도 타일", "base_url":"tiles/2020/Gwanak",min_zoom: 1, max_zoom:18, center: [14132174.6656499,4505966.6568925]},
  {"id": 13, "name": "구로 2020년도 타일", "base_url":"tiles/2020/Gurogu",min_zoom: 1, max_zoom:18, center:[14125055.8955331,4508396.0653522]},
  {"id": 14, "name": "금천 2020년도 타일", "base_url":"tiles/2020/Gumcheon",min_zoom: 1, max_zoom:18, center:[14125943.5571527,4502980.5059235]},
  {"id": 15, "name": "강서 2020년도 타일", "base_url":"tiles/2020/Gangseo",min_zoom: 1, max_zoom:18,center: [14120837.554749,4516181.0655914]},
  {"id": 16, "name": "강남 2020년도 타일", "base_url":"tiles/2020/Gangnam",min_zoom: 1, max_zoom:18,center:[14142863.2291974,4511459.8281494]},
  {"id": 17, "name": "강동 2020년도 타일", "base_url":"tiles/2020/Gangdong",min_zoom: 1, max_zoom:18, center:[14151353.4554407,4513259.3803656]},
  {"id": 18, "name": "강북 2020년도 타일", "base_url":"tiles/2020/Gangbuk",min_zoom: 1, max_zoom:18,center:[14140426.6681829,4528663.3258435]},
  {"id": 19, "name": "은평 2020년도 타일", "base_url":"tiles/2020/Eunpyeong",min_zoom: 1, max_zoom:18,center:[14129689.9032959,4523463.488252]},
  {"id": 20, "name": "동작 2020년도 타일", "base_url":"tiles/2020/Dongjack",min_zoom: 1, max_zoom:18,center:[14130840.5015527,4510778.4623271]},
  {"id": 21, "name": "동대문 2020년도 타일", "base_url":"tiles/2020/Dongdaemun",min_zoom: 1, max_zoom:18,center:[14141989.1485557,4519493.4637878]},
  {"id": 22, "name": "도봉 2020년도 타일", "base_url":"tiles/2020/Dobong",min_zoom: 1, max_zoom:18, center:[14142825.49189,4532738.9362204]},
  //
  {"id": 23, "name": "송파 2022년도 타일", "base_url":"tiles/2022/Songpa",min_zoom: 1, max_zoom:18, center:[14150004.31318136, 4510511.087837362]},
  {"id": 24, "name": "성동 2022년도 타일", "base_url":"tiles/2022/Seongdong",min_zoom: 1, max_zoom:18, center:[14141674.2257162,4517939.0183909]},
  {"id": 25, "name": "성북 2022년도 타일", "base_url":"tiles/2022/Seongbuk",min_zoom: 1, max_zoom:18,center:[14139439.820897,4521583.0877791]},
  {"id": 26, "name": "서대문 2022년도 타일", "base_url":"tiles/2022/Seodaemun",min_zoom: 1, max_zoom:18, center:[14130539.9389276,4520153.7656922]},
  {"id": 27, "name": "서초 2022년도 타일", "base_url":"tiles/2022/Seocho",min_zoom: 1, max_zoom:18, center:[14141204.1235066,4506726.1284877]},
  {"id": 28, "name": "노원 2022년도 타일", "base_url":"tiles/2022/Nowon",min_zoom: 1, max_zoom:18, center:[14143861.8763493,4530712.6024805]},
  {"id": 29, "name": "마포 2022년도 타일", "base_url":"tiles/2022/Mapo",min_zoom: 1, max_zoom:18, center: [14126609.3590272,4518341.9329551]},
  {"id": 30, "name": "중랑 2022년도 타일", "base_url":"tiles/2022/Jungrang",min_zoom: 1, max_zoom:18, center:[14147881.7344813,4523960.9011925]},
  {"id": 31, "name": "중구 2022년도 타일", "base_url":"tiles/2022/Junggu",min_zoom: 1, max_zoom:18, center:[14137308.3866068,4517993.3667139]},
  {"id": 32, "name": "종로 2022년도 타일", "base_url":"tiles/2022/Jongro",min_zoom: 1, max_zoom:18,center:[14135219.2537231,4519352.587922]},
  {"id": 33, "name": "광진 2022년도 타일", "base_url":"tiles/2022/Gwangjin",min_zoom: 1, max_zoom:18,center:[14146745.2737998,4514451.3451788]},
  {"id": 34, "name": "관악 2022년도 타일", "base_url":"tiles/2022/Gwanak",min_zoom: 1, max_zoom:18,center: [14132174.6656499,4505966.6568925]},
  {"id": 35, "name": "구로 2022년도 타일", "base_url":"tiles/2022/Gurogu",min_zoom: 1, max_zoom:18, center:[14125055.8955331,4508396.0653522]},
  // {"id": 49, "name": "구로 2022년도 타일", "base_url":"tiles/2022/guro",min_zoom: 1, max_zoom:18,},
  {"id": 36, "name": "강서 2022년도 타일", "base_url":"tiles/2022/Gangseo",min_zoom: 1, max_zoom:18,center: [14120837.554749,4516181.0655914]},
  {"id": 37, "name": "강남 2022년도 타일", "base_url":"tiles/2022/Gangnam",min_zoom: 1, max_zoom:18,center:[14142863.2291974,4511459.8281494]},
  {"id": 38, "name": "강동 2022년도 타일", "base_url":"tiles/2022/Gangdong",min_zoom: 1, max_zoom:18, center:[14151353.4554407,4513259.3803656]},
  {"id": 39, "name": "강북 2022년도 타일", "base_url":"tiles/2022/Gangbuk",min_zoom: 1, max_zoom:18,center:[14140426.6681829,4528663.3258435]},
  {"id": 40, "name": "은평 2022년도 타일", "base_url":"tiles/2022/Eunpyeong",min_zoom: 1, max_zoom:18,center:[14129689.9032959,4523463.488252]},
  {"id": 41, "name": "동작 2022년도 타일", "base_url":"tiles/2022/Dongjack",min_zoom: 1, max_zoom:18,center:[14130840.5015527,4510778.4623271]},
  {"id": 42, "name": "동대문 2022년도 타일", "base_url":"tiles/2022/Dongdaemun",min_zoom: 1, max_zoom:18,center:[14141989.1485557,4519493.4637878]},
  {"id": 43, "name": "도봉 2022년도 타일", "base_url":"tiles/2022/Dobong",min_zoom: 1, max_zoom:18, center:[14142825.49189,4532738.9362204]},
  {"id": 50, "name": "금천 2022년도 타일", "base_url":"tiles/2022/Gumcheon",min_zoom: 1, max_zoom:18, center:[14125943.5571527,4502980.5059235]},
  
  //
  {"id": 44, "name": "강남 2023년도 타일","base_url": "tiles/2023/Gangnam",min_zoom: 1, max_zoom:18, center:[14142863.2291974,4511459.8281494]},
  {"id": 45, "name": "마포 2023년도 타일","base_url": "tiles/2023/Mapo",min_zoom: 1, max_zoom:18, center: [14126609.3590272,4518341.9329551]},
  // {"id": 46, "name": "성북 2023년도 타일", "base_url":"tiles/2023/Seongbuk",min_zoom: 1, max_zoom:18,},
  {"id": 47, "name": "영등포 2023년도 타일","base_url": "tiles/2023/Yeongdeungpo",min_zoom: 1, max_zoom:18, center:[14125998.5489812,4512741.4212318]},
  {"id": 48, "name": "노원 2023년도 타일","base_url": "tiles/2023/Nowon",min_zoom: 1, max_zoom:18,center:[14143861.8763493,4530712.6024805]},
];

export const tiff_resources: TiffResource[] = [
  {"id": 1, type:"tiff", "name": "송파 2020년도", tile_ids:[1]},
  {"id": 2, type:"tiff", "name": "성북 2020년도", tile_ids:[2]},
  {"id": 3, type:"tiff", "name": "성동 2020년도", tile_ids:[3]},
  {"id": 4, type:"tiff", "name": "서대문 2020년도", tile_ids:[4]},
  {"id": 5, type:"tiff", "name": "서초 2020년도", tile_ids:[5]},
  {"id": 6, type:"tiff", "name": "노원 2020년도", tile_ids:[6]},
  {"id": 7, type:"tiff", "name": "마포 2020년도", tile_ids:[7]},
  {"id": 8, type:"tiff", "name": "중랑 2020년도", tile_ids:[8]},
  {"id": 9, type:"tiff", "name": "중구 2020년도", tile_ids:[9]},
  {"id": 10, type:"tiff", "name": "종로 2020년도", tile_ids:[10]},
  {"id": 11, type:"tiff", "name": "광진 2020년도", tile_ids:[11]},
  {"id": 12, type:"tiff", "name": "관악 2020년도", tile_ids:[12]},
  {"id": 13, type:"tiff", "name": "구로 2020년도", tile_ids:[13]},
  {"id": 14, type:"tiff", "name": "금천 2020년도", tile_ids:[14]},
  {"id": 15, type:"tiff", "name": "강서 2020년도", tile_ids:[15]},
  {"id": 16, type:"tiff", "name": "강남 2020년도", tile_ids:[16]},
  {"id": 17, type:"tiff", "name": "강동 2020년도", tile_ids:[17]},
  {"id": 18, type:"tiff", "name": "강북 2020년도", tile_ids:[18]},
  {"id": 19, type:"tiff", "name": "은평 2020년도", tile_ids:[19]},
  {"id": 20, type:"tiff", "name": "동작 2020년도", tile_ids:[20]},
  {"id": 21, type:"tiff", "name": "동대문 2020년도", tile_ids:[21]},
  {"id": 22, type:"tiff", "name": "도봉 2020년도", tile_ids:[22]},
  
  //2022
  {"id": 23, type:"tiff", "name": "송파 2022년도", tile_ids:[23]},
  {"id": 24, type:"tiff", "name": "성동 2022년도", tile_ids:[24]},
  {"id": 25, type:"tiff", "name": "성북 2022년도", tile_ids:[25]},
  {"id": 26, type:"tiff", "name": "서대문 2022년도", tile_ids:[26]},
  {"id": 27, type:"tiff", "name": "서초 2022년도", tile_ids:[27]},
  {"id": 28, type:"tiff", "name": "노원 2022년도", tile_ids:[28]},
  {"id": 29, type:"tiff", "name": "마포 2022년도", tile_ids:[29]},
  {"id": 30, type:"tiff", "name": "중랑 2022년도", tile_ids:[30]},
  {"id": 31, type:"tiff", "name": "중구 2022년도", tile_ids:[31]},
  {"id": 32, type:"tiff", "name": "종로 2022년도", tile_ids:[32]},
  {"id": 33, type:"tiff", "name": "광진 2022년도", tile_ids:[33]},
  {"id": 34, type:"tiff", "name": "관악 2022년도", tile_ids:[34]},
  {"id": 35, type:"tiff", "name": "구로 2022년도", tile_ids:[35]},
  {"id": 36, type:"tiff", "name": "강서 2022년도", tile_ids:[36]},
  {"id": 37, type:"tiff", "name": "강남 2022년도", tile_ids:[37]},
  {"id": 38, type:"tiff", "name": "강동 2022년도", tile_ids:[38]},
  {"id": 39, type:"tiff", "name": "강북 2022년도", tile_ids:[39]},
  {"id": 40, type:"tiff", "name": "은평 2022년도", tile_ids:[40]},
  {"id": 41, type:"tiff", "name": "동작 2022년도", tile_ids:[41]},
  {"id": 42, type:"tiff", "name": "동대문 2022년도", tile_ids:[42]},
  {"id": 43, type:"tiff", "name": "도봉 2022년도", tile_ids:[43]},
  {"id": 50, type:"tiff", "name": "금천 2022년도", tile_ids:[50]},

  //2023
  {"id": 44, type:"tiff", "name": "강남 2023년도",tile_ids:[44]},
  {"id": 45, type:"tiff", "name": "마포 2023년도", tile_ids:[45]},
  // {"id": 46, type:"tiff", "name": "성북 2023년도",  tile_ids:[46]},
  {"id": 47, type:"tiff", "name": "영등포 2023년도", tile_ids:[47]},
  {"id": 48, type:"tiff", "name": "노원 2023년도",tile_ids:[48]},
];

export const geo_resources: GeoResource[] = [
  {id:1, type:"geo", name:"도봉_2020_2022", url: "geojsons/Dobong_2020_2022.geojson"},
  {id:2, type:"geo", name:"동대문_2020_2022", url: "geojsons/Dongdaemun_2020_2022.geojson"},
  {id:3, type:"geo", name:"동작_2020_2022", url: "geojsons/Dongjack_2020_2022.geojson"},
  {id:4, type:"geo", name:"은평_2020_2022", url: "geojsons/Eunpyeong_2020_2022.geojson"},
  {id:5, type:"geo", name:"강북_2020_2022", url: "geojsons/Gangbuk_2020_2022.geojson"},
  {id:6, type:"geo", name:"강동_2020_2022", url: "geojsons/Gangdong_2020_2022.geojson"},
  {id:7, type:"geo", name:"강남_2022_2023", url: "geojsons/Gangnam_2022_2023.geojson"},
  {id:8, type:"geo", name:"강서_2020_2022", url: "geojsons/Gangseo_2020_2022.geojson"},
  {id:9, type:"geo", name:"권촌_2020_2022", url: "geojsons/Guncheon_2020_2022.geojson"},
  {id:10, type:"geo", name:"구로구_2020_2022", url: "geojsons/Gurogu_2020_2022.geojson"},
  {id:11, type:"geo", name:"관악_2020_2022", url: "geojsons/Gwanak_2020_2022.geojson"},
  {id:12, type:"geo", name:"광진_2020_2022", url: "geojsons/Gwangjin_2020_2022.geojson"},
  {id:13, type:"geo", name:"종로_2020_2022", url: "geojsons/Jongro_2020_2022.geojson"},
  {id:14, type:"geo", name:"중구_2020_2022", url: "geojsons/Junggu_2020_2022.geojson"},
  {id:15, type:"geo", name:"중랑_2020_2022", url: "geojsons/Jungrang_2020_2022.geojson"},
  {id:16, type:"geo", name:"마포_2022_2023", url: "geojsons/Mapo_2022_2023.geojson"},
  {id:17, type:"geo", name:"노원_2022_2023", url: "geojsons/Nowon_2022_2023.geojson"},
  {id:18, type:"geo", name:"서초_2020_2022", url: "geojsons/Seocho_2020_2022.geojson"},
  {id:19, type:"geo", name:"서대문_2020_2022", url: "geojsons/Seodaemun_2020_2022.geojson"},
  {id:20, type:"geo", name:"성북_2020_2022", url: "geojsons/Seongbuk_2020_2022.geojson"},
  {id:21, type:"geo", name:"성동_2020_2022", url: "geojsons/Seongdong_2020_2022.geojson"},
  {id:22, type:"geo", name:"송파_2020_2022", url: "geojsons/Songpa_2020_2022.geojson"},
  // {id:23, type:"geo", name:"영등포_2022_2023", url: "geojsons/Yeongdeungpo_2022_2023.geojson"}
];

export const resources = [...tiff_resources, ...geo_resources];

export const templates: TaskTemplate[] = [
  {
    id:1,
    name:"변화탐지 1.0",
    type:"change-detection",
    version:1,
    version_name:"1.0",
    inputs:[
      {order:1, type:"tiff", name:"기준 이미지"},
      {order:2, type:"tiff", name:"비교 이미지"}
    ],
    outputs:[{order:1, type:"geo", name:"탐지 객채"}]
  },
];

export const tasks: Task[] = [
  {id:1, name:"도봉 2020_2022", project_id:1, template_id:1, status:"done", input_ids:[22,43], output_ids:[1]},
  {id:2, name:"동대문 2020_2022", project_id:2, template_id:1, status:"done", input_ids:[21,42], output_ids:[2]},
  {id:3, name:"동작 2020_2022", project_id:3, template_id:1, status:"done", input_ids:[20,41], output_ids:[3]},
  {id:4, name:"은평 2020_2022", project_id:4, template_id:1, status:"done", input_ids:[19,40], output_ids:[4]},
  {id:5, name:"강북 2020_2022", project_id:5, template_id:1, status:"done", input_ids:[18,39], output_ids:[5]},
  {id:6, name:"강동 2020_2022", project_id:6, template_id:1, status:"done", input_ids:[17,38], output_ids:[6]},
  {id:7, name:"강남 2022_2023", project_id:7, template_id:1, status:"done", input_ids:[16,37], output_ids:[7]},
  {id:8, name:"강서 2020_2022", project_id:8, template_id:1, status:"done", input_ids:[15,36], output_ids:[8]},
  
  {id:9, name:"금천 2020_2022", project_id:9, template_id:1, status:"done", input_ids:[14,50], output_ids:[9]},

  {id:10, name:"구로 2020_2022", project_id:10, template_id:1, status:"done", input_ids:[13,35], output_ids:[10]},
  {id:11, name:"관악 2020_2022", project_id:11, template_id:1, status:"done", input_ids:[12,34], output_ids:[11]},
  {id:12, name:"광진 2020_2022", project_id:12, template_id:1, status:"done", input_ids:[11,33], output_ids:[12]},
  {id:13, name:"종로 2020_2022", project_id:13, template_id:1, status:"done", input_ids:[10,32], output_ids:[13]},
  {id:14, name:"중구 2020_2022", project_id:14, template_id:1, status:"done", input_ids:[9,31], output_ids:[14]},
  {id:15, name:"중랑 2020_2022", project_id:15, template_id:1, status:"done", input_ids:[8,30], output_ids:[15]},
  {id:16, name:"마포 2022_2023", project_id:16, template_id:1, status:"done", input_ids:[7,29], output_ids:[16]},
  {id:17, name:"노원 2022_2023", project_id:17, template_id:1, status:"done", input_ids:[6,28], output_ids:[17]},
  {id:18, name:"서초 2020_2022", project_id:18, template_id:1, status:"done", input_ids:[5,27], output_ids:[18]},
  {id:19, name:"서대문 2020_2022", project_id:19, template_id:1, status:"done", input_ids:[4,26], output_ids:[19]},
  {id:20, name:"성북 2020_2022", project_id:20, template_id:1, status:"done", input_ids:[3,25], output_ids:[20]},
  {id:21, name:"성동 2020_2022", project_id:21, template_id:1, status:"done", input_ids:[2,24], output_ids:[21]},
  {id:22, name:"송파 2020_2022", project_id:22, template_id:1, status:"done", input_ids:[1,23], output_ids:[22]},
  // {id:23, name:"영등포 2022_2023", project_id:23, template_id:1, status:"done", input_ids:[,47], output_ids:[23]},
];

//ok
export const projects: Project[] = [
  {id:0, name:"서울 변화탐지", task_ids: tasks.map((task)=>task.id)},
  {id:1, name:"도봉 변화탐지", task_ids: [1]},
  {id:2, name:"동대문 변화탐지", task_ids: [2]},
  {id:3, name:"동작 변화탐지", task_ids: [3]},
  {id:4, name:"은평 변화탐지", task_ids: [4]},
  {id:5, name:"강북 변화탐지", task_ids: [5]},
  {id:6, name:"강동 변화탐지", task_ids: [6]},
  {id:7, name:"강남 변화탐지", task_ids: [7]},
  {id:8, name:"강서 변화탐지", task_ids: [8]},
  {id:9, name:"금청 변화탐지", task_ids: [9]},
  {id:10, name:"구로 변화탐지", task_ids: [10]},
  {id:11, name:"관악 변화탐지", task_ids: [11]},
  {id:12, name:"광진 변화탐지", task_ids: [12]},
  {id:13, name:"종로 변화탐지", task_ids: [13]},
  {id:14, name:"중구 변화탐지", task_ids: [14]},
  {id:15, name:"중랑 변화탐지", task_ids: [15]},
  {id:16, name:"마포 변화탐지", task_ids: [16]},
  {id:17, name:"노원 변화탐지", task_ids: [17]},
  {id:18, name:"서초 변화탐지", task_ids: [18]},
  {id:19, name:"서대문 변화탐지", task_ids: [19]},
  {id:20, name:"성북 변화탐지", task_ids: [20]},
  {id:21, name:"성동 변화탐지", task_ids: [21]},
  {id:22, name:"송파 변화탐지", task_ids: [22]},
]

export function getTile(tile_id: number){
  return tiles.find((tile)=>tile.id===tile_id);
}

export function getTask(task_id: number){
  return tasks.find((task)=>task.id===task_id) as Task;
}

export function getTemplate(template_id: number){
  return templates.find((template)=>template.id===template_id) as TaskTemplate;
}

export function getTiffResource(tiff_resource_id: number){
  return tiff_resources.find((tiff_resource)=>tiff_resource.id===tiff_resource_id) as TiffResource;
}

export function getGeoResource(geo_resource_id: number){
  return geo_resources.find((geo_resource)=>geo_resource.id===geo_resource_id) as GeoResource;
}

export function getProject(project_id: number){
  return projects.find((project)=>project.id===project_id) as Project;
}