import { create } from 'zustand'
// import { persist } from 'zustand/middleware';
import { Task } from '../../dummy-server/dtos';
import { getGeoResource, getTile, tasks } from '../../dummy-server';
import TileLayer from 'ol/layer/Tile';
import { XYZ } from 'ol/source';
import { Feature, Map } from 'ol';
import VectorSource from 'ol/source/Vector';
import GeoJSON from 'ol/format/GeoJSON';
import VectorLayer from 'ol/layer/Vector';
import Swipe from "ol-ext/control/Swipe";
import UndoRedo from "ol-ext/interaction/UndoRedo";
import Bar from 'ol-ext/control/Bar';
import EditBar from 'ol-ext/control/EditBar';
import Button from "ol-ext/control/Button";

export type MODE = 'swipev'|'swipeh'|'compare'|'view1'|'view2';
class SwipeManager {

  map1?: Map;
  map2?: Map;
  swipe?: Swipe;
  tile1SourceUrl?: string;
  tile2SourceUrl?: string;
  tile1Layer?: TileLayer<XYZ>;
  tile2Layer?: TileLayer<XYZ>;
  vectorSource?: VectorSource<Feature>;
  vectorLayer?: VectorLayer<Feature>;
  undoInteraction?: UndoRedo;
  mainBar?: Bar;

  mode: MODE = 'compare';

  protected createBaseMap(){
    const map = new Map({
      layers: [new TileLayer({
        source: new XYZ({
          url: 'https://tile.openstreetmap.org/{z}/{x}/{y}.png'
        }),
      })],
    });
    return map;
  }

  setMode(mode: MODE){
    this.mode = mode;
    if(this.map2 && this.swipe){
      this.map2.removeControl(this.swipe);
      if(mode){
        switch(mode){
          case 'swipev':
          case 'swipeh':
            this.map2.addControl(this.swipe);
            this.swipe.set('orientation', mode ==='swipev'?'vertical':'horizontal');
            break;
        }
      }
      this.map2.updateSize();
      if(this.map1)
        this.map1.updateSize();
    }
  }

  setTask(task: Task, mode?: MODE){

    this.map1 = this.createBaseMap();
    this.map2 = this.createBaseMap();

    this.swipe = new Swipe();
    this.undoInteraction = new UndoRedo();
    this.mainBar = new Bar();

    this.tile1SourceUrl = `${getTile(task.input_ids[0])?.base_url}/{z}/{x}/{-y}.png`;
    
    this.tile1Layer = new TileLayer({
      source: new XYZ({
        url: this.tile1SourceUrl,
      }),
      // maxZoom: 18,
      // minZoom: 1,
    });
    
    this.tile2SourceUrl = `${getTile(task.input_ids[1])?.base_url}/{z}/{x}/{-y}.png`;
    
    this.tile2Layer = new TileLayer({
      source: new XYZ({
        url: this.tile2SourceUrl,
      }),
      // maxZoom: 18,
      // minZoom: 1,
    })

    this.vectorSource = new VectorSource({
      url: `${getGeoResource(task.output_ids[0])?.url}`,
      format: new GeoJSON(),
    })

    this.vectorLayer = new VectorLayer({
      source: this.vectorSource,
    })

    //수정 버튼들
    this.mainBar.addControl(new EditBar ({
      source: this.vectorSource,
      interactions: { Info: false }
    }));

    const handleUndo = ()=>{
      if(this.undoInteraction)
        this.undoInteraction.undo();
    }

    const handleRedo = ()=>{
      if(this.undoInteraction)
        this.undoInteraction.redo();
    }

    // redo undo
    this.mainBar.addControl(new Bar({ 
      group: true,
      controls: [
        new Button({
          html: '<i class="fa fa-undo" ></i>',
          title: 'undo...',
          handleClick:()=>{
            handleUndo();
          }
        }),
        new Button({
          html: '<i class="fa fa-repeat" ></i>',
          title: 'redo...',
          handleClick:()=>{
            handleRedo();
          }
        })
      ]
    }));

    this.map1.addLayer(this.tile1Layer);
    this.map1.addLayer(this.vectorLayer);
    
    this.map2.addLayer(this.tile1Layer);
    this.map2.addLayer(this.tile2Layer);
    this.map2.addLayer(this.vectorLayer);
    this.map2.addControl(this.mainBar);
    
    this.swipe.set('orientation', 'horizontal');

    this.swipe.addLayer(this.tile1Layer, false);
    this.swipe.addLayer(this.tile2Layer, true);
    if(mode){
      console.log(mode);
      
      this.setMode(mode);
    }
  }

}

interface SwipeMapState {
  swipeManager: SwipeManager;
  task: Task;
  // vectorSource: VectorSource;
  setTask: (task: Task) => void;
  map1: Map;
  map2: Map;
  mode: MODE;
  setMode: (mode: MODE) => void;
  center: [number, number];
  zoom: number;
}

const useSwipeMapStore = create<SwipeMapState>()(
    (set, get) => ({
      swipeManager: new SwipeManager(),
      task: tasks[0],
      setTask: (task: Task) => {
        const { swipeManager } = get();
        const mode = swipeManager.mode

        swipeManager.setTask(task, mode);

        const center = getTile(task.input_ids[0])?.center;

        set({ 
          task,
          map1:swipeManager.map1,
          map2:swipeManager.map2,
          center: center,
          zoom: 13,
        });
      },
      map1: new Map(),
      map2: new Map(),
      mode: 'compare',
      setMode: (mode: MODE) => {
        console.log('setMode', mode);
        const { swipeManager } = get();
        swipeManager.setMode(mode);
        const { map1, map2 } = get();
        set({ mode, map1, map2 });
      },
      center: [0,0],
      zoom: 13,
    })
);

export default useSwipeMapStore;