import ResourceListItem, { DenseChip } from "../../../../../../design/atoms/ResourceListItem";
import { GeoResource } from "../../../../../../dummy-server/dtos";
// import { MapIcon } from "../../../../../design/atoms/icons";
// import DenseIconButton from "../../../../../design/atoms/buttons/DenseIconButton";

export default function GeoResourceList({ resources }: { resources: GeoResource[] }){

  // const handleClick = (r: GeoResource) => {
  //   console.log(r);
  // }
  
  return (
      <>
      {resources.map((resource)=>(
            <ResourceListItem key={resource.id}>
              <div style={{userSelect: "none"}}>{resource.name}</div>
              <DenseChip type={resource.type}/>
              <div style={{flexGrow: 1}}/>
              {/* <DenseIconButton
                onClick={()=>handleClick(resource)}
                color='primary'>
                <MapIcon />
              </DenseIconButton> */}
            </ResourceListItem>
      ))}
      </>
  );
}